<template>
  <page-layout ref="layout">

    <template #actions="{state}">
      <b-button v-if="state.editing" size="sm" variant="outline-link" @click="state.editing = false">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Editing</span>
      </b-button>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="support-docs">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
    </template>

    <template #content="{ state }">
      <b-alert :show="enabled !== true" variant="danger">
        This system is currently disabled, and only visible to those with the administrator privileges.
      </b-alert>

      <b-card class="bg-transparent shadow-none">
        <b-row>
          <b-col v-if="state.editing" cols="12" lg="10" align-self="center" class="mx-auto mb-8 d-flex justify-content-end mb-1">
            <can do="create" on="support-docs">
              <b-button v-b-modal="'document-category-modal'" size="sm" variant="primary" class="mr-1">
                <font-awesome-icon icon="fas fa-plus" class="mr-50" /> Create Category
              </b-button>
              <document-category-modal id="document-category-modal"
                                       :categories="categories.items"
                                       @created="addCategory"/>
            </can>
            <can do="create" on="support-docs">
              <b-button v-b-modal="'document-content-modal'" size="sm" variant="secondary">
                <font-awesome-icon icon="fas fa-plus" class="mr-50" /> Create Document
              </b-button>
              <document-content-modal id="document-content-modal"
                                      :categories="categories.items"
                                      @created="addDocument"/>
            </can>
          </b-col>
          <b-col cols="12" lg="10" class="mx-auto mb-8">
            <b-row>
              <b-col v-for="(category, index) in categories.items" :key="category.title" cols="6" md="4">
                <b-card no-body class="shadow-none border">
                  <b-card-header header-class="px-1">
                    <div class="d-flex align-items-center w-100">
                      <b-avatar rounded class="mr-1" size="md" variant="light-primary">
                        <font-awesome-icon :icon="category.icon" size="lg"></font-awesome-icon>
                      </b-avatar>

                      <div class="d-flex justify-content-between w-100">
                        <div>
                          <h5 class="mb-0">{{ category.title }}</h5>
                          <small>{{ category.description }}</small>
                        </div>
                        <template v-if="state.editing">
                          <can do="create" on="support-docs">
                            <b-button v-b-modal="`document-content-modal-${index}`" size="sm" variant="link" title="Create Document" v-b-tooltip="'Create Document'" class="p-50">
                              <font-awesome-icon icon="fas fa-plus" />
                            </b-button>
                            <document-content-modal :id="`document-content-modal-${index}`"
                                                    :current-category="category"
                                                    :categories="categories.items"
                                                    @created="addDocument"/>
                          </can>
                        </template>
                      </div>
                    </div>
                  </b-card-header>

                  <b-list-group flush>
                    <b-list-group-item v-for="document in category.documents.items.filter(item => item.isPublished || $can('manage', 'support-docs')).slice(0, 4)"
                                       :key="document.id" class="pr-1"
                                       :to="{ name: 'support-docs-document', params: { category: category.slug, slug: document.slug, documentId: document.id }}">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center font-small-3">
                          {{ document.title }}
                          <span v-if="!document.isPublished" class="ml-50 text-danger font-small-2">(Unpublished)</span>
                        </div>
                        <!-- Management Options -->
                        <template v-if="$can('manage', 'support-docs') && state.editing">
                          <b-dropdown id="page-layout-options" v-b-tooltip="'Options'" variant="link" size="sm" no-caret toggle-class="p-25" right>
                            <template #button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>
                            <can do="update" on="support-docs">
                              <b-dropdown-item @click="updateDocument(document)">
                                <font-awesome-icon :icon="`fa-solid fa-${document.isPublished ? 'eye-slash' : 'eye'}`"></font-awesome-icon>
                                <span class="align-middle ml-50">{{ document.isPublished ? 'Unpublish' : 'Publish' }} Document</span>
                              </b-dropdown-item>
                            </can>
                            <can do="delete" on="support-docs">
                              <b-dropdown-divider/>
                              <b-dropdown-item @click="$refs.layout.confirmDelete({category, document}, deleteDocument, { confirm: { shouldParse: true, text: `Delete the <b>${document.title}</b> document?` } })">
                                <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
                                <span class="align-middle ml-50">Delete Document</span>
                              </b-dropdown-item>
                            </can>
                          </b-dropdown>
                        </template>
                      </div>
                    </b-list-group-item>
                  </b-list-group>

                  <b-card-text class="text-left">
                    <b-button size="small" variant="link" class="font-small-3"
                              :to="{ name: 'support-docs-category', params: { slug: category.slug, id: category.id, categories: categories }}">
                      View All Documents
                    </b-button>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </page-layout>
</template>
<script>

import {API, graphqlOperation} from 'aws-amplify';
import {listDocumentCategories} from './queries/documents';
import notify from '@/mixins/notify.mixin';
import PageLayout from '@/components/PageLayout.vue';
import DocumentCategoryModal from '@/views/support/docs/DocumentCategoryModal.vue';
import DocumentContentModal from '@/views/support/docs/DocumentContentModal.vue';
import {deleteDocument, updateDocument} from '@/views/support/docs/queries/document-content';
import slugify from 'slugify';


export default {
  name: 'SupportLanding',
  components: {
    DocumentContentModal,
    DocumentCategoryModal,
    PageLayout,
  },
  mixins: [ notify ],
  props: {
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      categories: {
        items: [],
        loading: false,
      },
    }
  },
  async mounted() {
    await this.listDocumentCategories()
    this.$refs.layout.state.loading = false
  },
  methods: {
    async listDocumentCategories(nextToken, pagedItems) {
      this.categories.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentCategories, { limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentCategories.items)

      if(response.data.listDocumentCategories.nextToken) {
        await this.listDocumentCategories(response.data.listDocumentCategories.nextToken, items)
      }
      else {
        this.categories.items = items.sort((a, b) => a.title.localeCompare(b.title))
        this.categories.loading = false
      }
    },
    addCategory(category) {
      this.categories.items.push(category)
    },
    addDocument(document) {
      const documentCategory = this.categories.items.find(item => item.id === document.categoryID)
      if(documentCategory) {
        documentCategory.documents.items.push(document)
      }
    },
    async updateDocument(document) {
      try {
        document.isPublished = !document.isPublished
        const input = { id: document.id, isPublished: document.isPublished }
        await API.graphql(graphqlOperation(updateDocument, { input: input }));
        this.notify({ title: 'Success', text: 'Document was successfully updated', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to update Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async deleteDocument({ category, document }) {
      try {
        const input = { id: document.id }
        await API.graphql(graphqlOperation(deleteDocument, { input: input }));
        category.documents.items = category.documents.items.filter(item => item.id !== document.id)
        this.notify({ title: 'Success', text: 'Document was successfully deleted', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to delete Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
  }
}
</script>
