// eslint-disable-next-line import/prefer-default-export
export const listDocumentCategories = /* GraphQL */ `
    query ListDocumentCategories(
        $id: ID
        $filter: ModelDocumentCategoryFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDocumentCategories(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                title
                description
                icon
                documents {
                    items {
                        id
                        slug
                        title
                        isPublished
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
